<template>
    <main class="main">
        <nav aria-label="breadcrumb" class="breadcrumb-nav mb-2">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">{{ $t('home') }}</router-link>
                    </li>
                    <!-- <li class="breadcrumb-item">
                        <router-link to="/shop">{{ $t('shop') }}</router-link>
                    </li> -->
                    <li class="breadcrumb-item active">{{ $t('compare') }}</li>
                </ol>
                <h4>{{ $t('compare_product') }}</h4>
            </div>
        </nav>
        <div class="page-content">
            <div class="container" v-if="compareProducts.length <= 0">No product found!</div>

            <div class="container compare-main py-5" v-if="compareProducts.length > 0">
                <div class="row">
                    <div class="col-md-3"></div>
                    <div class="col-md-3" v-for="(product, index) in compareProducts" :key="index">
                        <product-home :product="product" :isCompare="true"></product-home>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-9">
                        <div class="product-compare-table">
                            <table class="table">
                                <tbody>
                                    <tr v-for="item in commonAttributes" :key="item.attribute_id">
                                        <th>{{ item.label }}</th>
                                        <td>{{ item.value1 }}</td>
                                        <td>{{ item.value2 }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- mobile -->
            <div class="container compare-mobile py-5" v-if="compareProducts.length > 0">
                <div class="row">
                    <div class="col-md-12">
                        <div class="product-compare-table">
                            <div class="row border-bottom">
                                <div class="col-6 border-right" v-for="(product, index) in compareProducts"
                                    :key="index">
                                    <product-mobile-compare :product="product" />
                                    <h6 class="mt-3"><strong>{{ $t('product_description') }}</strong></h6>
                                    <p v-if="product.description.html" v-html="product.description.html"></p>
                                    <p v-else>-</p>
                                    <h6 class="border-top pt-2"><strong>{{ $t('short_description') }}</strong></h6>
                                    <p v-if="product.short_description.html" v-html="product.short_description.html">
                                    </p>
                                    <p v-else>-</p>
                                </div>
                                <!-- <div class="col-6">
                                    <product-mobile-compare />
                                    <h6 class="mt-3"><strong>Omschrijving</strong></h6>
                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi sapiente at
                                        aperiam, quidem vero labore numquam est alias eveniet beatae.</p>
                                </div> -->
                            </div>
                            <!-- <div class="row border-bottom mb-2">
                                <div class="col-6 border-right pt-2">
                                    <h6><strong>Short Description</strong></h6>
                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi sapiente at
                                        aperiam, quidem vero labore numquam est alias eveniet beatae.</p>
                                </div>
                                <div class="col-6 pt-2">
                                    <h6><strong>Short Description</strong></h6>
                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Commodi sapiente at
                                        aperiam, quidem vero labore numquam est alias eveniet beatae.</p>
                                </div>
                            </div> -->
                            <table class="table table-striped">
                                <tbody>
                                    <tr v-for="item in commonAttributes" :key="item.attribute_id">
                                        <th>{{ item.label }}: <br />
                                            <p class="text-dark">{{ item.value1 }}</p>
                                        </th>
                                        <td>{{ item.value2 }}</td>
                                    </tr>
                                    <tr>
                                        <th v-for="(item, index) in compareProducts" :key="index">
                                            <div class="compare-amount-cart">
                                                <h4 class="mb-0">
                                                    <strong>{{ formatCurrency(item.price_range.maximum_price.final_price.value) }}</strong>
                                                </h4>
                                                <button class="product-cart" @click="addToCart(item)" :disabled="item.stock_qty === 0">
                                                    <font-awesome-icon icon="fa-solid fa-shopping-cart" />
                                                </button>
                                            </div>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <!-- mobile -->
        </div>
    </main>
</template>

<script>
import ProductHome from '@/esf_weert_mobilesupplies/core/components/elements/products/ProductHome'
import ProductMobileCompare from '@/esf_weert_mobilesupplies/core/components/elements/products/ProductMobileCompare'

export default {
    // props: {
    //     products: {
    //         type: Array,
    //         required: true
    //     }
    // },
    // data() {
    //     return {
    //         selectedProducts: []
    //     }
    // },
    components: {
        ProductHome,
        ProductMobileCompare
    },
    mounted() {
        this.$store.dispatch("product/loadCompareProducts");
    },
    computed: {
        compareProducts() {
            return this.$store.getters["product/getProductCompare"]
        },
        // commonAttributes() {
        //     const commonAttributes = this.compareProducts[0].information_attributes.map(attr1 => {
        //         const matchingAttr = this.compareProducts[1].information_attributes.find(attr2 => attr1.attribute_code === attr2.attribute_code);
        //         if (matchingAttr) {
        //             return {
        //                 label: attr1.label,
        //                 value1: attr1.value,
        //                 value2: matchingAttr.value
        //             };
        //         }
        //     }).filter(Boolean);
        //     return commonAttributes;
        // }
        commonAttributes() {
            const attributes1 = this.compareProducts[0] || { information_attributes: [] };
            const attributes2 = this.compareProducts[1] || { information_attributes: [] };
            const allLabels = [...new Set([...attributes1.information_attributes.map(attr => attr.label), ...attributes2.information_attributes.map(attr => attr.label)])];

            const result = allLabels.map(label => {
                const attr1 = attributes1.information_attributes.find(attr => attr.label === label);
                const attr2 = attributes2.information_attributes.find(attr => attr.label === label);

                return {
                    label: label,
                    value1: attr1 ? attr1.value : "-",
                    value2: attr2 ? attr2.value : "-"
                };
            });
            return result;
        }
    },
    methods: {
        // selectProduct(product) {
        //     this.selectedProducts.push(product)
        // },
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
        },
        async addToCart(item) {
            if (item.__typename == "SimpleProduct") {
                const item =
                    '{data: {sku: "' + item.sku + '", quantity:' + 1 + "}}  ";
                let retval = await this.$store.dispatch("cart/addToCart", {
                    type: item.__typename,
                    item: item,
                });

                if (retval == false) {
                    const msg = {
                        type: "danger",
                        title: this.$t("shopping_basket"),
                        text: this.$t("not_add_product"),
                    };
                    this.$store.dispatch("messages/sendMessage", { message: msg });
                } else {
                    const msg = {
                        type: "success",
                        title: this.$t("shopping_basket"),
                        text: this.$t("added_product"),
                    };
                    this.$store.dispatch("messages/sendMessage", { message: msg });
                }
            } else {
                this.$router.push("/" + item.url_key + "/");
            }
        }
    }
}
</script>