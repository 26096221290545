<template>
    <div>
        <product-comparison></product-comparison>
    </div>
</template>

<script>
import ProductComparison from '@/esf_weert_mobilesupplies/core/components/partial/product/comparison/ProductComparison';
// import { homeData } from '@/esf_weert_mobilesupplies/utilities/data';
export default {
    // data() {
    //     return {
    //         // products: [],
    //         loaded: false
    //     }
    // },
    components: {
        ProductComparison
    }
}
</script>