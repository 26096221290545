<template>
    <div class="product-mobile-compare">
        <div class="product-mobile-compare-image">
            <img :src="product.thumbnail.large" alt="productImage">
        </div>
        <div class="product-mobile-compare-meta">
            <p class="title" :title="product.name">{{ productName }}</p>
            <div class="ratings-container">
                <div class="ratings">
                    <div class="ratings-val" style="width: 100%;"></div>
                    <span class="tooltip-text">5</span>
                </div>
            </div>
            <!-- <p class="status" v-if="product.stock_status === 'IN_STOCK'">{{
                            $t('in_stock') }}</p>
                                            <p class="status text-danger" v-else>{{ $t('out_of_stock') }}</p> -->
        </div>
        <div class="product-mobile-compare-action">
            <div>
                <p class="sale-price">{{ formatCurrency(product.price_range.maximum_price.final_price.value) }}</p>
                <p class="price">{{ formatCurrency(product.price_range.maximum_price.regular_price.value) }}</p>
            </div>
            <button class="product-mobile-compare-cart" @click="handleOpen">
                <font-awesome-icon icon="fa-solid fa-shopping-cart" />
            </button>
        </div>

        <!-- badges -->
        <div class="product-mobile-compare-badges" @click="removeCompare">
            <font-awesome-icon class="pr-4" icon="fa-solid fa-close" />
        </div>

        <!-- badges -->


        <!-- Cart Popup -->
        <dialog-box width="90%" :title="productName" :isVisible="cartDialog" :handleClose="handleClose">
            <template v-slot:default>
                <div class="container">
                    <div class="row mb-1 align-items-center">
                        <div class="col-4">
                            <img :src="product.thumbnail.large" alt="productImage">
                        </div>
                        <div class="col-4">
                            <p>
                                <strong>
                                    {{ productName }}
                                </strong>
                            </p>
                        </div>
                        <div class="col-4">
                            <p>
                                <strong> {{ formatCurrency(product.price_range.maximum_price.final_price.value)
                                    }}</strong>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 mb-1">
                            <button class="btn btn-success w-100" @click="handleClose">
                                {{ $t('compare') }}
                            </button>
                        </div>
                        <div class="col-md-12">
                            <router-link to="/">
                                <button class="btn btn-dark w-100">
                                    {{ $t('continue_shopping') }}
                                </button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </template>
        </dialog-box>
        <!-- Cart Popup -->
    </div>
</template>

<script>
import DialogBox from '@/esf_weert_mobilesupplies/core/components/elements/DialogBox';

export default {
    components: {
        DialogBox
    },
    props: {
        product: Object
    },
    data() {
        return {
            nameMaxLength: 15,
            cartDialog: false
        }
    },
    computed: {
        productName() {
            if (this.product.name.length <= this.nameMaxLength) {
                return this.product.name;
            } else {
                const words = this.product.name.split(' ');
                let shortenedString = '';
                let count = 0;
                for (let i = 0; i < words.length; i++) {
                    if (count + words[i].length <= this.nameMaxLength) {
                        shortenedString += words[i] + ' ';
                        count += words[i].length;
                    } else {
                        break;
                    }
                }
                return shortenedString.trim() + '...';
            }
        }
    },
    methods: {
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
        },
        handleOpen() {
            this.cartDialog = true;
        },
        handleClose() {
            this.cartDialog = false;
        },
        removeCompare() {
            this.$store.dispatch("product/removeCompareProduct", { sku: this.product.sku });
            const msg = {
                type: "success",
                title: "Success",
                text: "Product removed from compare list",
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
        }
    }
}
</script>